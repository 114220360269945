#add-data-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* #add-data-section div > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
} */

#add-data-section form > * {
    margin: 15px;
}

/* #add-data-section form > .form-group * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} */
