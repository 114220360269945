#home {
    display: block;
    text-align: center;
}

.down-arrow {
    font-size: 100px;
    font-weight: bolder;
    border-radius: 50px;
}

@media only screen and (max-width: 767px) {
    #home {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 768px) {
    #home {
        padding: 0 50px;
    }
}