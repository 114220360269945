#skills {
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#skills > p {
    padding: 6px 30px;
    width: 200px;
    margin: 0 auto;
    font-size: 16px;
}

#skills .skill-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    width: fit-content;
    margin: 15px auto;

}

.skill-card {
    display: block !important;
    padding: 0;
    width: 80px;
    height: 80px;
    text-align: center;
    /* background-color: gray; */
    font-size: 10px;
    margin: 4px;
}

.skill-card img {
    width: 40px;
    height: 40px;
    margin-top: 6px;
}

.skill-card h3 {
    margin-top: 0;
}

@media only screen and (min-width: 768px) {
    #skills > p {
        width: fit-content
    }

    #skills .skill-grid {
        grid-template-columns: auto auto auto auto;
    }

    .skill-card {
        width: 160px;
        height: 160px;
        margin: 20px;
        font-size: 21px;
    }

    .skill-card img {
        width: 80px;
        height: 80px;
        margin-top: 6px;
    }
}